import React from "react";
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import { store } from "./store";
import App from "./containers/App/index";

const NextApp = () =>
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </HashRouter>
  </Provider>;


export default NextApp;
