import React, { useEffect, useState } from "react";
import { Divider, Layout, Popover } from "antd";
import { Link, useHistory } from "react-router-dom";

import languageData from "./languageData";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";


import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import CustomScrollbars from "../../util/CustomScrollbars";
import Auxiliary from "../../util/Auxiliary";
import { switchLanguage, toggleCollapsedSideNav } from "../../store/settingSlice";
import { ROUTES, ROUTES_TITLE } from "../../constants/commonConst";

const { Header } = Layout;

const Topbar = () => {
  const { locale, navStyle, navCollapsed, width } = useSelector((state: RootState) => state.setting);
  const [searchText, setSearchText] = useState('');
  const [title, setTitle] = useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname == ROUTES.TRANSFER_MANAGEMENT) {
      setTitle(ROUTES_TITLE.TRANSFER_MANAGEMENT)
    } else if (history.location.pathname == ROUTES.PLAN_SETTING) {
      setTitle(ROUTES_TITLE.PLAN_SETTING)
    } else if (history.location.pathname == ROUTES.CHANGE_PASSWORD) {
      setTitle(ROUTES_TITLE.CHANGE_PASSWORD)
    } else if (history.location.pathname == ROUTES.SEND_NOTIFICATION) {
      setTitle(ROUTES_TITLE.SEND_NOTIFICATION)
    }

  }, [history.location])

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  return (
    <Header style={{ marginTop: 54 }}>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={("/assets/images/w-logo.png")} /></Link>
      <h2 style={{ fontWeight: 'bold', margin: 'auto', fontSize: 24, color: '#2C3E50' }}>{title}</h2>
      {(history.location.pathname == ROUTES.SEND_NOTIFICATION || history.location.pathname == ROUTES.TRANSFER_MANAGEMENT) && <Divider style={{ backgroundColor: "#C0C0C0", margin: '-14px 0 0 -12px' }} />}
      {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                 placeholder="Search in app..."
                 onChange={updateSearchChatUser}
                 value={searchText}/>
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
                       placeholder="Search in app..."
                       onChange={updateSearchChatUser}
                       value={searchText}/>
          } trigger="click">
            <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                       content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover>
            </li>
          </Auxiliary>
        }
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul> */}
    </Header>
  );
};

export default Topbar;
