import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Menu, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/commonConst";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import { RootState, useAppDispatch } from "../../store";
import { logout } from "../../store/authSlice";
import CustomScrollbars from "../../util/CustomScrollbars";
import AppsNavigation from "./AppsNavigation";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";


const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { themeType, navStyle, pathname } = useSelector(
    (state: RootState) => state.setting
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [visible, setVisible] = useState(false);


  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0];

  const logOut = () => {
    dispatch(logout());
    setVisible(false);
  }

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />

      <div className="gx-sidebar-content">
        {/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={"light"}
            className='background-color-gray menu-custom'
            style={{ paddingLeft: 16, paddingRight: 16 }}
            mode="inline"
          >
            <Menu.Item style={{ height: '100%' }} key={'logo'}>
              <Avatar size={140} shape="square" src="/assets/images/Logo.png" style={{ borderRadius: 0 }}/>
            </Menu.Item>
            <Menu.Item
              style={{ height: 63 }}
              className={`gx-menu-group gx-mt-3 gx-radius-4 ${pathname == ROUTES.SEND_NOTIFICATION ? 'background-color-white' : 'background-color-gray'} `}
              key={'通知送信'}
              icon={<img src={("/images/iconNotification.png")} width={32} height={32} style={{ marginRight: 5 }} />}
              onClick={() => {
                history.push(`${ROUTES.SEND_NOTIFICATION}`)
              }}
            >
              <span className="gx-text-primary gx-font-weight-bold gx-fontsize-18">
                通知送信
              </span>
            </Menu.Item>
            <Menu.Item
              style={{ height: 63 }}
              className={`gx-menu-group gx-mt-3 gx-radius-4 ${pathname == ROUTES.TRANSFER_MANAGEMENT ? 'background-color-white' : 'background-color-gray'} `}
              key={'振込管理'}
              icon={<img src={("/images/iconTransferManagement.png")} width={32} height={32} style={{ marginRight: 5 }} />}
              onClick={() => {
                history.push(`${ROUTES.TRANSFER_MANAGEMENT}`)
              }}
            >
              <span className="gx-text-primary gx-font-weight-bold gx-fontsize-18">
                振込管理
              </span>
            </Menu.Item>
            <Menu.Item
              style={{ height: 63 }}
              className={`gx-menu-group gx-mt-3 gx-radius-4 ${pathname == ROUTES.PLAN_SETTING ? 'background-color-white' : 'background-color-gray'}`}
              key={'プラン設定'}
              icon={<img src={("/images/iconSetting.png")} width={32} height={32} style={{ marginRight: 5 }} />}
              onClick={() => {
                history.push(`${ROUTES.PLAN_SETTING}`)
              }}
            >
              <span className="gx-text-primary gx-font-weight-bold gx-fontsize-18" >
                プラン設定
              </span>
            </Menu.Item>
            <Menu.Item
              style={{ height: 63 }}
              className={`gx-menu-group gx-mt-3 gx-radius-4 ${pathname == ROUTES.CHANGE_PASSWORD ? 'background-color-white' : 'background-color-gray'}`}
              key={'パスワード変更'}
              icon={<img src={("/images/iconChangePassword.png")} width={32} height={32} style={{ marginRight: 5 }} />}
              onClick={() => {
                history.push(`${ROUTES.CHANGE_PASSWORD}`)
              }}
            >
              <span className="gx-text-primary gx-font-weight-bold gx-fontsize-18">
                パスワード変更
              </span>
            </Menu.Item>
            <Menu.Item
              style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', }}
              className={`gx-menu-group gx-mt-3 customMenuItem background-color-gray`}
              key={'logout'}
              icon={<img src={("/images/iconLogout.png")} width={24} height={24} style={{ marginRight: 5 }} />}
              onClick={() => {
                setVisible(true);
              }}
            >
              <span className="gx-font-weight-bold gx-fontsize-14" style={{ color: '#A1A1A1', }}>
                ログアウト
              </span>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>

        <Modal
          visible={visible}
          centered
          style={{ backgroundColor: 'transparent' }}
          bodyStyle={{ backgroundColor: 'transparent' }}
          footer={null}
          closable={false}
          wrapClassName="my-modal"
        >
          <div className="customModalContent">
            <span className="gx-fontsize-16">ログアウトしてもよろしいですか？</span><br />
            <div className="gx-fontsize-16 gx-font-weight-bold" style={{ color: '#F34D4D', marginTop: 25, cursor: 'pointer' }}
              onClick={logOut}
            >
              ログアウト
            </div>
          </div>
          <div className="customModalFooter gx-fontsize-16 gx-font-weight-bold"
            onClick={() => { setVisible(false) }}
          >
            キャンセル
          </div>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
