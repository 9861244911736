import { ConfigProvider } from 'antd';
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import { ROUTES } from "../../constants/commonConst";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import AppLocale from "../../lngProvider";
import { RootState, useAppDispatch } from "../../store";
import { me, setToken } from "../../store/authSlice";
import { changePathname, setInitUrl } from "../../store/settingSlice";
import { getLocalStore, LocalKey, setLocalStore } from "../../util/localStore";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import MainApp from "./MainApp";


const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props => <Component {...props} />}
  />;
const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  const { locale, navStyle, layoutType, themeType, isDirectionRTL, initURL } = useSelector((state: RootState) => state.setting);
  const { authUser, loaded, loading: isLoadingUser } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      setLocalStore(LocalKey.TOKEN, token);
      dispatch(setToken(token));
    } else {
      const token = getLocalStore(LocalKey.TOKEN);
      dispatch(setToken(token));
    }

    dispatch(me());
  }

  useEffect(() => {
    dispatch(changePathname(location.pathname));
  }, [location])

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname + location.search));
    }
  });

  useEffect(() => {
    if (!authUser && loaded) {
      history.push('/signin');
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      history.push(ROUTES.SEND_NOTIFICATION);
    } else {
      history.push(initURL);
    }
  }, [authUser, loaded, initURL, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return isLoadingUser ? <CircularProgress /> : (
    <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default App;
