import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { onNavStyleChange } from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { RootState, useAppDispatch } from "../../store";
import { toggleCollapsedSideNav } from "../../store/settingSlice";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { themeType, width, navStyle } = useSelector((state: RootState) => state.setting);
  const dispatch = useAppDispatch();

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    dispatch(onNavStyleChange(NAV_STYLE_DRAWER));
  }

  return (
    <div className=" background-color-gray" style={{ height: 72 }}>
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        {/* <i
          className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? '' : ''}`}
          onClick={() => {
            setSidebarCollapsed(!sidebarCollapsed)
          }}
        /> */}
      </div> : null}

      {/* <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
          <img alt="lo" src={("/assets/images/w-logo.png")} /> :
          themeType === THEME_TYPE_LITE ?
            <img alt="logo1" src={("/assets/images/logo-white.png")} /> :
            <img alt="logo2" src={("/assets/images/logo.png")} />}
      </Link> */}
    </div>
  );
};

export default SidebarLogo;
