import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import { API_RESULT, ROUTES } from "../constants/commonConst";
import { RootState, useAppDispatch } from "../store";
import { getListSystemParams } from "../store/adminSlice";

import asyncComponent from "../util/asyncComponent";
import { showToast, ToastType } from "../util/showToast";
// const { type } = useParams<any>();
const App = ({ match }) => {
  const { hiddenLeftTopBottomLayout } = useSelector((state: RootState) => state.setting);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getListSystemParams());
      } catch (error) {
        showToast(ToastType.error, "エラーが発生しました");
      }
    })();
  }, [dispatch]);

  return (
    <div className={!hiddenLeftTopBottomLayout ? "gx-main-content-wrapper" : ""} style={{ flex: 1 }}>
      <Switch>
        <Route path={`/sample`} component={asyncComponent(() => import('./SamplePage')) as any} />
        <Route exact path={`${ROUTES.TRANSFER_MANAGEMENT}`} component={asyncComponent(() => import('./TransferManagement')) as any} />
        <Route exact path={`${ROUTES.PLAN_SETTING}`} component={asyncComponent(() => import('./PlanSetting')) as any} />
        <Route exact path={`${ROUTES.CHANGE_PASSWORD}`} component={asyncComponent(() => import('./ChangePassword'))as any} />
        <Route exact path={`${ROUTES.SEND_NOTIFICATION}`} component={asyncComponent(() => import('./SendNotification'))as any} />
      </Switch>
    </div>
  )
};

export default App;
