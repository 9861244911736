import { Button, Checkbox, Form, Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { BUTTON_SUBMIT_FORM } from "../constants/commonConst";
import { RootState, useAppDispatch } from "../store";
import { login, me } from "../store/authSlice";
import IntlMessages from "../util/IntlMessages";

const SignIn = () => {
  // const {isLoading, error, userLogin} = useAuth();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = async (values) => {
    const result = await dispatch(login({ ...values, isAdmin: true }));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(me());
    }
  };

  return (
    <div className="" style={{ backgroundColor: '#fff', height: '100%' }}>
      <div className="" style={{ width: '30%', margin: 'auto', paddingTop: '7%' }}>
        <div className="gx-text-primary gx-font-weight-bold gx-text-center" style={{ fontSize: '40px', }}>
          ログイン
        </div>
        <div className="gx-text-center gx-fontsize-24 gx-font-weight-bold" style={{ marginTop: '24px', }}>
          ようこそ
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ marginTop: '70px', }}
          className="gx-signin-form gx-form-row0 "
        >
          <Form.Item
            label={
              <span className='gx-font-weight-bold gx-fontsize-20'>
                ログインID
              </span>
            }
            rules={[{ required: true, message: 'ログインIDを入力してください' }]} name="userName">
            <Input className="gx-p-2 gx-pl-4 gx-fontsize-20 gx-mt-2 gx-radius-16" placeholder="ログインIDを入力してください" />
          </Form.Item>
          <Form.Item
            label={
              <span className='gx-font-weight-bold gx-fontsize-20'>
                パスワード
              </span>
            }
            rules={[{ required: true, message: 'パスワードを入力してください' }]} name="userPassword">
            <Input className="gx-p-2 gx-pl-4 gx-fontsize-20 gx-mt-2 gx-radius-16" type="password" placeholder="パスワードを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button {...BUTTON_SUBMIT_FORM} htmlType="submit" style={{ height: 44, width: '100%', borderRadius: 16, fontWeight: 'bold', marginTop: '10%' }}>
              ログイン
            </Button>
          </Form.Item>
        </Form>
      </div>
      <AppNotificationContainer loading={loading} error={error} />
    </div>
  );
};

export default SignIn;
