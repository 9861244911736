import antdEN from "antd/lib/locale-provider/en_US";
import enMessages from "../locales/en_US.json";
import common from "../locales/en_US/common_en.json";
const EnLang = {
  messages: {
    ...enMessages,
    ...common,

  },
  antd: antdEN,
  locale: 'en-US',
};
export default EnLang;
