import { message as toastNotification } from "antd";

export enum ToastType {
  success,
  info,
  error
}
export const showToast = (type: ToastType, message: string, onClose?: VoidFunction) => {
  switch (type) {
    case ToastType.success:
      toastNotification.success(message, onClose);
      break;
    case ToastType.info:
      toastNotification.info(message, onClose);
      break;
    case ToastType.error:
      toastNotification.error(message, onClose);
      break;
  }
}
