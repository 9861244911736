import { message } from 'antd';
import axios from 'axios';
import { showToast, ToastType } from './showToast';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (req) => {
  let originalRequest = req;
  const token = localStorage.getItem("token");

  if (token) {
    originalRequest.headers.Authorization = 'Bearer ' + token;
    return Promise.resolve(originalRequest);
  }

  return req;
})

// Add a response interceptor
api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.message == 'Network Error') {
    showToast(ToastType.error, '現在インターネットに接続できません。接続を確認して、もう一度お試しください。');
  }
  if (error.response.status === 403) {
    window.location.href = '#/403'
  }
  return Promise.reject(error);
});

export const setBearerToken = (token: string) => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
