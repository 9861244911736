import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../util/api';
import { convertParamsToQuery } from '../util/convertParamsToQuery';
import _ from 'lodash';
import { DEFAULT_PAGING } from '../constants/commonConst';
import { GetListUser, SendMessage, SysParamPut, TransferFilter, TransferStatus } from '../routes/commonInterfaces';


const initialState = {
  loading: false,
  error: '',
  message: '',
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
  listTransfer: {
    data: [],
    count: 0
  },
  listUser: {
    data: [],
    count: 0,
    all: []
  },
  listParam: [],
}

export const getListSystemParams = createAsyncThunk(
  'tbl-system-params/getListSystemParams',
  async () => {
    const response = await api.get(`tbl-system-params`);
    return response.data
  }
)

export const changeAdminPassword = createAsyncThunk(
  'tbl-user/changeAdminPassword',
  async (payload: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(`tbl-user/change-admin-password`, payload);
      return response.data
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response)
      return rejectWithValue(errorObj)
    }
  }
)

export const updateSystemParamTask = createAsyncThunk(
  'tbl-system-params/updateSystemParamTask',
  async (payload: { id: string, req: SysParamPut }) => {
    const response = await api.put(`tbl-system-params/${payload.id}`, payload.req);
    return response.data
  }
)

export const deleteCamGroupTask = createAsyncThunk(
  'mst-cam-group/deleteCamGroupTask',
  async (id: number) => {
    const response = await api.delete(`mst-camera-group/${id}`);
    return response.data
  }
)

export const getListTransfer = createAsyncThunk(
  'card/getListTransfer',
  async (filter: TransferFilter & { page: number, size: number }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      let query = `card/bank-transfer?page=${filter.page}&size=${filter.size}`;

      if(filter.emails && filter.emails.length > 0) {
        const emails = filter.emails.filter(x => x).toString().replace(/,/g, '&&emails[]=');
        query = `${query}&emails[]=${emails}`;
      }

      if((filter.fromMoney && filter.toMoney) || filter.fromMoney === 0 || filter.toMoney === 0) {
        query = `${query}&fromMoney=${filter.fromMoney}&toMoney=${filter.toMoney}`;
      }

      if(filter.fromDate && filter.toDate) {
        filter.fromDate = filter.fromDate.slice(0, filter.fromDate.indexOf('+'));
        filter.toDate = filter.toDate.slice(0, filter.toDate.indexOf('+'));
        query = `${query}&fromDate=${filter.fromDate}&toDate=${filter.toDate}`;
      }

      const response = await api.get(query);
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
)

export const updateStatusTransfer = createAsyncThunk(
  'card/updateStatusTransfer',
  async (payload: { id: string, req: TransferStatus, totalMonth: number }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.put(`card/bank-transfer/${payload.id}`, { status: payload.req, totalMonth: payload.totalMonth });
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
)

export const getListUserToSendNotification = createAsyncThunk(
  'user/getListUserToSendNotification',
  async (filter: GetListUser, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      let query = '';

      if(filter?.emails && filter?.emails.length > 0) {
        const emails = filter.emails.toString().replace(/,/g, '&emails[]=');
        query = `emails[]=${emails}`;
      }

      if((filter?.fromMoney && filter?.toMoney) || filter?.fromMoney === 0 || filter?.toMoney === 0) {
        query = `${query}&fromMoney=${filter?.fromMoney}&toMoney=${filter?.toMoney}`;
      }

      if(filter?.fromDate && filter?.toDate) {
        filter.fromDate = filter.fromDate.slice(0, filter.fromDate.indexOf('+'));
        filter.toDate = filter.toDate.slice(0, filter.toDate.indexOf('+'));
        query = `${query}&fromDate=${filter.fromDate}&toDate=${filter.toDate}`;
      }

      const response = await api.get(`${query ? `tbl-user?${query}` : 'tbl-user'}`);
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
)

export const sendNotification = createAsyncThunk(
  'notification/sendNotification',
  async (payload: SendMessage, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('tbl-notification', payload);
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
)

export const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListSystemParams.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getListSystemParams.fulfilled, (state, action) => {
        state.loading = false;
        state.listParam = action.payload.content;
      })
      .addCase(getListSystemParams.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(changeAdminPassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(changeAdminPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeAdminPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(updateSystemParamTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSystemParamTask.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSystemParamTask.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCamGroupTask.pending, (state) => {
        state.loading = true;
        state.actionResult = false;
        state.error = '';
      })
      .addCase(deleteCamGroupTask.fulfilled, (state, action) => {
        state.loading = false;
        state.actionResult = true;
        state.message = "common.deleteeSuccess";
      })
      .addCase(deleteCamGroupTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(getListTransfer.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getListTransfer.fulfilled, (state, action) => {
        state.loading = false;
        state.listTransfer.data = action.payload.content;
        state.listTransfer.count = action.payload.total;
      })
      .addCase(getListTransfer.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(updateStatusTransfer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatusTransfer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStatusTransfer.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(getListUserToSendNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListUserToSendNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.listUser.data = action.payload.data;
        state.listUser.count = action.payload.count;
        state.listUser.all = action.payload.users;
      })
      .addCase(getListUserToSendNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || '';
      })
  },

})

export const { } = adminSlice.actions
export const selectAdmin = (state: any) => state.admin
export default adminSlice.reducer
