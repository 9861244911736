import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { api, setBearerToken } from '../util/api';
import { LocalKey, removeLocalStore, setLocalStore } from '../util/localStore';

export interface AuthState {
  loading: boolean,
  error: string,
  authUser?: AuthUser,
  loaded: boolean,
  token: string,
  trackingRoute: boolean,
}
export interface AuthUser {
  userID: number,
  username: string,
  userFullname: string,
  isAdmin: boolean,
  isActive: boolean,
  mainFunction?: string,
  mainLanguage?: string,
  signatureFile?: string
  userStatus: boolean,
  permissions: string[]
}

const initialState: AuthState = {
  loading: false,
  loaded: false,
  error: '',
  token: '',
  trackingRoute: false,
}

export const login = createAsyncThunk(
  'auth/login',
  async (payload: { userName: string, userPassword: string }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('auth/login', {
        ...payload
      });
      return response.data.token;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response)
      return rejectWithValue(errorObj)
    }
  }
)

export const me = createAsyncThunk(
  'auth/me',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get('auth/me')
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response)
      return rejectWithValue(errorObj)
    }
  }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.authUser = undefined;
      state.token = '';
      removeLocalStore(LocalKey.TOKEN);
    },
    setToken: (state, action) => {
      state.token = action.payload;
      setLocalStore(LocalKey.TOKEN, action.payload);
      setBearerToken(action.payload);
    },
    setTrackingRoute: (state, action) => {
      state.trackingRoute = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        setLocalStore(LocalKey.TOKEN, action.payload);
        setBearerToken(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = "メールアドレスまたはパスワードが間違って います";
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(me.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.authUser = action.payload;
      })
      .addCase(me.rejected, (state) => {
        state.loaded = true;
        state.loading = false;
      })
  },

})

export const { logout, setToken, setTrackingRoute } = authSlice.actions
export const selectAuth = (state: any) => state.auth
export default authSlice.reducer
