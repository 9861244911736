import { TablePaginationConfig } from "antd";
import { BaseButtonProps } from "antd/lib/button/button";
import { AbstractTooltipProps } from "antd/lib/tooltip";

export const PAGE_SIZE = 20;
export const PAGE_SIZE_GET_ALL = 9999999;
export const TABLE_SETTING: TablePaginationConfig = {
  position: ["bottomRight"],
  hideOnSinglePage: true,
  size: "small",
  pageSize: PAGE_SIZE,
  disabled: false,
};
export const DEFAULT_FORM_ITEM = {
  bordered: false,
};
export const DEFAULT_PAGING = {
  page: 0,
  size: 0,
  total: 0,
};

export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_DD_MM_YYYY = "DD-MM-YYYY HH:mm";
export const DATE_TIME_FORMAT_DD_MM_YYYY_HH_MM_SS = "DD-MM-YYYY HH:mm:ss";
export const DATE_TIME_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";

export const TIME_FORMAT_HH_MM_SS = "HH:mm:ss";

export const BUTTON_ICON_ACTION_LIST: BaseButtonProps = {
  type: "default",
  className: "gx-mb-0",
};
export const BUTTON_PRIMARY: BaseButtonProps = {
  type: "primary",
  className: "gx-mb-0",
};
export const BUTTON_SUBMIT_FORM: BaseButtonProps = {
  type: "default",
  className: "gx-btn gx-text-white gx-mb-0 gx-background-primary gx-fontsize-18",
};
export const BUTTON_CANCEL_FORM: BaseButtonProps = {
  type: "default",
  className: "gx-btn gx-mb-0 background-btn-cancel gx-fontsize-18",

};
export const BUTTON_EXPORT_FORM: BaseButtonProps = {
  type: "default",
  className: "gx-btn gx-text-white gx-mb-0 gx-border-primary gx-fontsize-18",
};
export const TOOLTIP_TOP_BLACK: AbstractTooltipProps = {
  placement: "top",
  color: "black",
  zIndex: 9999999999,
};

export const ROUTES = {
  TRANSFER_MANAGEMENT: "/transfer-management",
  PLAN_SETTING: "/plan-setting",
  CHANGE_PASSWORD: "/change-password",
  SEND_NOTIFICATION: "/send-notification",
};

export const ROUTES_TITLE = {
  TRANSFER_MANAGEMENT: "振り込み状況確認リスト",
  PLAN_SETTING: "プラン設定",
  CHANGE_PASSWORD: "パスワード変更",
  SEND_NOTIFICATION: "通知送信",
};

export const API_RESULT = {
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  PENDING: "pending",
};


export const COMMON_MESSAGE = {
  CREATE_SUCCESS: '正常に追加されました',
  CREATE_FAILED: '新しい失敗を追加する',
  UPDATE_SUCCESS: '更新に成功',
  UPDATE_FAILED: 'アップデートに失敗しました',
  UPDATED: '更新完了'

}
